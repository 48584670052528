
/* #n1,
#n2,
#n3{
   padding-top: 10rem;

} */

.personCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}




.top{
    z-index: 10;
    width: 100%;
    height: 4rem;
    position: relative!important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    top:0;
    background: rgb(0, 0, 0);

    /* background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6895133053221288) 44%, rgba(0, 212, 255, 0) 100%); */

}

.top-logo{
    margin-left: 1rem;

}


.carousel {
    width: 100%;
}



.carousel-container {
    width: 80%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    /* background-color: rgba(250, 235, 215, 0.467); */
    border-radius: 50px;
    padding: 0;
}

.carousel-container-big {
    background-image: url("../../assets/background-team.png");
        background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 100vw;
    height: fit-content;
    min-height: 70vh;
    
}

.carousel-cell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: .6s ease opacity, .6s ease transform;
    opacity: 1;
    
     
}

.opacity-trans{
    animation: opacity-animation 1s;
}

@keyframes opacity-animation{
    0%{
        opacity: 0.3;
        scale: 0.95;
    }

    100%{
        opacity: 1;
        scale: 1;
    }
}
/* .top {
    width: 100%;
    height: 4rem;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 0;
    
} */
.next{
    height: 80px;
    width: 80px;
    font-size: 50px;
    border-radius: 50%;
    margin: 2rem;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.invisible{
    display:none;
    opacity: 0;
    transition: 2s;
}
@media screen and (max-width: 1500px) {

    .carousel-container{
        transform: scale(0.9);
        width: 100%;
        height: 100%;

    }
    .carousel-cell {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
                align-items: center;
            /* align-items: flex-start;
            justify-content: flex-start; */
    }
    .invisible {
            display: none;
        }
    
    .carousel-container-big{
        height: fit-content;
    }
    /* .top{
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: fit-content;
    } */

    .top-logo{
        margin: 1rem 0;

    }
    .lang-switch{
        /* flex-direction: column !important; */
        align-items: flex-end!important;
        justify-content: center!important;
        /* margin-top: 4rem; */
        margin: 0 0 1rem 0;
    }
    .top{
        height: fit-content;
        flex-direction: column;
    }
}

.dropbtn {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 23px;
    text-decoration: none;
    padding: 5px;
    border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
    font-size: 23px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;

    /* min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1; */
}

/* Links inside the dropdown */
.dropdown-content button {
    color: black;
    /* padding: 12px 16px; */
    /* text-decoration: none; */
    display: block;
    border: none;
    font-size: 23px;
    width: 100%;
    text-align: start;
}

/* Change color of dropdown links on hover */
.dropdown-content button:hover {
    background-color: #cacaca
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: #c35fa4;
}

.lang-switch{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.lang-switch h1{
    color: #fff;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0rem!important;
    padding: 0!important;
    font-size: 20px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25.5px;
    padding: 0;
    margin: 0;
}

.switch input {
    /* opacity: 0; */
    /* width: 0;
    height: 0; */
    width: 100%;
    height: 100%;
}



.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 19.5px;
    width: 19.5px;
    left: 0;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch input:checked+.slider {
    background-color: #2196F3;
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 19.5px;
}

.slider.round:before {
    border-radius: 50%;
}