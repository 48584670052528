#about {
    width: 100%;
    background-color: #e8e4e4 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin: 0rem;
    height: fit-content;

    
}

.about-text {
    font-weight:bold;
    font-size: 90px;
    color: #e8e4e4;
    text-shadow: -1px -1px 0 var(--color-bg), 1px -1px 0 var(--color-bg), -1px 1px 0 var(--color-bg), 1px 1px 0 var(--color-bg);
    transition: 0.8s color;
    width: fit-content;
    user-select: none;
    margin-top: 3rem;
}

.about-text:hover {
    color: var(--color-bg)
}

.meet-team-text {
    font-weight:bold;
    font-size: 50px;
    color: #e8e4e4;
    text-shadow: -1px -1px 0 var(--color-bg), 1px -1px 0 var(--color-bg), -1px 1px 0 var(--color-bg), 1px 1px 0 var(--color-bg);
    transition: 0.8s color;
    width: fit-content;
    user-select: none;
    margin-top: 3rem;  
}

.meet-team-text:hover {
    color: var(--color-bg)
}


.services-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-size: 3rem;
    margin-top: 4rem;
    /* flex: 2; */
}

.service {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 2rem;
    height: 28rem;
    border-radius: 1rem;
    margin: 0 1rem 0 1rem;
    box-shadow:  12px 12px 24px #bebebe,
    -12px -12px 24px #ffffff;
    transition: 0.5s;
}
.details{
    display: flex;
    justify-content: center;
    align-items: center;
     width: 100%;
    height: 100%;
    margin: 0 !important; 
    font-size: 20px!important;
}

.service:hover{
    height: 29rem;
    cursor: pointer;
}

.invisible{
    opacity: 0;
}

.service-animation {
    animation: mb-app-animation 2s;
}

@keyframes mb-app-animation {
    0% {
        opacity: 0;
        transform: translateX(50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
    
}

.members-animation {
    animation: members-anim 2s;
}

@keyframes members-anim {
    0% {
        opacity: 0;
        transform: translateX(-20%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
    
}

.about-txt-animation {
    animation: about-txt-anim 3s;
}

@keyframes about-txt-anim {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.meet-team-txt-animation {
    animation: meet-team-txt-anim 3s;
}

@keyframes meet-team-txt-anim {
    0% {
        opacity: 0;

    }
    100% {
        opacity: 1;
    }
    
}

.text {
    font-size: 1rem;
    margin-top: 1.5rem;
    color: black;
    margin-top:3rem;
    background-color: #e8e4e4;
}



.text-websites {
    font-size: 1rem;
    margin-top: 1.5rem;

}

.text-eshop {
    font-size: 1rem;
    margin-top: 1.5rem;

}

.icons {
    transition: .7s transform ease-in;
}

.flip-in {
    transform: rotateY(180deg);
    /* transform: scale(1.3); */
}

.flip-out {
    transform: rotateY(-180deg);

}

button {
    box-shadow: 0;
}


.svg-icon {
    width: 20rem;
    
}

.mobile-svg {
    width: 15rem;
}

.about-header {
    text-shadow: #000 0px 0px 2px;
    -webkit-font-smoothing: antialiased;
    font-family: 'Oxygen', sans-serif;
    font-weight: bolder;
    font-size: 5rem;
    color: var(--color-bg-light);

}

.meet-team-container {
    /* display: flex;
    flex-direction: row; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    margin: 0;
    padding: 0;
    height: 100%;
    /* justify-content: center;
    align-items: center;
    flex: 2; */
    /* overflow: hidden; */
    /* position: relative; */
}

.team-member {

    /* background-color: aquamarine; */
    /* overflow: hidden; */
    /* perspective: 700px; */
    /* position: absolute; */
    /* object-fit:cover; */
    /* position: relative; */
    /* background-size: cover; */
    /* background-size: cover;
    background-position: center; */
    width: 100%;
    height: 100%;
    /* transition: transform 2s cubic-bezier(0.77, 0, 0.175, 1), */
    /* clip-path: 0.2s linear;*/
    position: absolute;
    
}
.gray {
    /* cursor: none; */
    /* width: 100%;*/
    height: 100%;
    /* transform: rotateY(45deg); */
    /* filter: grayscale(100%);  */
    filter: grayscale(100%) sepia(20%) brightness(80%);
    background-image: url(../../assets/horgos.jpg);  
    /* transition: 0.5s ease;
    top: 0; */
}
.masked {
    height: 100%;

    /* cursor: none; */
    /* width: 100%;
    height: 100%;
    /* transform: rotateY(45deg); */
    /* filter: grayscale(100%);     */
    /* transition: 0.5s ease; */
    /* top: 0;
    object-fit:cover; */ 
    background-image: url(../../assets/horgos.jpg);  
    clip-path: circle(25% at 50% 50%);

}


/* .team-member img:hover {
    filter: grayscale(0);    
} */

.cursor {
    background: url(../../assets/cursor.svg);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 300px;
    /* height: 6rem; */
}

.onomata{
    font-size: 25px;
    padding-bottom: 25rem;
}
.members-container {
    width: 100%;
    padding-bottom: 3rem;
    height: 100% ;
}

.meet-our-team{
    margin-top: 5rem;
    color: #000;
}

.flex-about{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow: hidden; */
}

.container {
    width: 1200px;
    height: 100%;
    overflow: visible;
}

@media (max-width: 1200px) {
    .services-container {
        flex-direction: column;
        gap: 1rem;
        width: 95%;
    }
    .members-container {
        width: 95%;
    }
    .service{
        margin: 0;   
    }

    .about-text {
        font-size: 95% ;
    }

    .meet-team-text {
        font-size: 95%;
    }
}



.flip-card {
    perspective: 1000px;
    
}

.flip-card-inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    -moz-backface-visibility: hidden;
    backface-visibility: hidden
}

/* .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
} */

.flip-trans{
    transform: rotateY(180deg);
}




.flip-card-front,
.flip-card-back {
    /* width: 100%;
    height: 100%; */
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    
}

.flip-card-back {
    
    transform: rotateY(180deg);
}

