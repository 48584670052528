

.scene {
  /* margin: 40px 0; */
  position: relative;
  width: 100%;
  height: 66.66%;
  /* margin: 40px 0; */ 
  perspective: 1000px;
}

.carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translateZ(-3px);
  transform-style: preserve-3d;
  transition: transform 1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel__cell {
  position: absolute;
  width: 400;
  height: 60;
  /* left: 10px;
  top: 10px; */
  border: 2px solid transparent;
  line-height: 80px;
  font-size: 80%;
  font-weight: bold;
  color: #000;
  text-align: center;
  opacity: 0.4;
  text-shadow: #fff 0px 0px 1px;
  -webkit-font-smoothing: antialiased;
}

.carousel__cell:nth-child(9n+1) { background: transparent; }
.carousel__cell:nth-child(9n+2) { background: transparent; }
.carousel__cell:nth-child(9n+3) { background: transparent; }

.carousel__cell:nth-child(1) { transform: rotateX(0deg) translateZ(102px); transition: 2s;}
.carousel__cell:nth-child(2) { transform: rotateX(120deg) translateZ(102px);  transition: 2s;}
.carousel__cell:nth-child(3) { transform: rotateX(240deg) translateZ(102px);  transition: 2s;}


p {
  margin-left: 8rem;
}

.cell_selected {
  opacity: 1;
  color: #fff
}