
#contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-image: url(../../assets/contact.jpg);
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
}
/* #contact input{
    outline: 0;
    border: 0;
    border-bottom: 3px;
    border-color: #212529   ;
} */

.container {
    width: 1200px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.form-container {
    width: 100%;
    height: 80%;
    background-color: rgba(0,0,0, 0.94);
    border-radius: 2rem;
    color: #212529;
    backdrop-filter: blur(5px);
    box-shadow:  20px 20px 65px #1d1d1d,
             -20px -20px 65px #0f0e0e;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* backdrop-filter: blur(7px); */
    color: white;


}

.form-input {
    border: 0;
    outline: 0;
    background: rgba(255,255, 255, 0.05);
    border-bottom: 1px solid rgb(255, 255, 255, 0.5);
    width: 70%;
}
#product{
    width: 100% !important;
}
.form-input input {
    background: rgba(255,255, 255, 0.00);
    border-bottom: 1px solid rgb(255, 255, 255, 0.5);
    border: 0;
    outline: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.form-message {
    background: rgba(255,255, 255, 0.05);
    border: 1px solid rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    width: 100%;
}
.messagebox-container{
    width: 70%;
}
.form-title {
    padding-top: 2rem;
    height: fit-content;
}

.form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    width: 80%;
    font-size: 20px;
    /* padding-bottom: 5rem; */

}

.neomorphism-contact {
    border-radius: 0.4rem;
    background: #ffffff8f; 
    border: 0px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 2rem;
    color: rgb(0, 0, 0);
    width: 100%;
    justify-content: flex-end;
    cursor: pointer;
}


.button-container {
    width: 70%;
    height: 20%; 
}

.neomorphism-contact:hover {
    border: 1px solid;
    border-color: #ffffff60;

}
/* form div {
    margin: 1rem 0;
} */

.contact-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    height: 100%;
}

.form-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.contact-else {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.form-option {
    background: rgba(255,255, 255, 0.05);
}

.selection-box {
    /* background-color: rgba(255,255, 255, 0.05); */
    color:rgb(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    width: 70%;
    
    /* width: 100%; */
}

.selection-box option {
    color: #000000;
}


.title {
    font-size: 40px;
    border-bottom: 1px solid white;
    width: 85%;
    margin-bottom: 5rem;
}

.paragraph {
    font-size: 20px;
    font-style: italic;
    width: 80%;
}

.signature{
    width: 70%;
    text-align: right;
    font-family: 'Ms Madi', cursive;
}

.gradient{
    padding: 0;
    margin: 0;
    height: 5rem;
    width: vw;
    background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5312260646446079) 59%, rgba(0,0,0,0) 100%);
}

.form-container-animation {
    animation: form-container-anim 2s;
}

@keyframes form-container-anim {
    0% {
        opacity: 0.3;
        transform: translateY(20%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@media (max-width: 1200px) {
    .container {
        width: 100%;
    }

    .contact-box {
        flex-direction: column-reverse;
    }

    .form-container {
        height: fit-content;
        padding-bottom: 2rem;
    }

    .title{
        margin: 0 !important;
        padding: 1rem 0 !important;
    }

    .paragraph{
        margin: 2rem 0;
    }

    .form-input{
        margin: 1rem 0;
    }

    textarea{
        margin: 1rem 0;
    }

    #contact {
        height: 100%;
    }
}