.gallery {
    height: 600px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gallery-item {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    will-change: transform;
    border-radius: 15px;
    clip-path: inset(100% 0% 0% 0%);
    transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);
}

.gallery-item-wrapper {
    margin: 1rem;
    height: 100%;
    width: 100%;
    transform: scale(1);
    transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1);
}
.gallery-item {
    clip-path: inset(0% 0% 0% 0%);
    opacity: 1;
}


.gallery-animate-end {
    opacity: 1;
}

.gallery-animate-start {
    opacity: 0.1;
    transition: 4s opacity ease-out;
}

.gallery-item-image {
    transform-origin: scale(1);
}

.gallery-item-image {
    cursor: pointer;
    background-size: cover;
    background-position: center;
    transform-origin: center;
    width: 100%;
    height: 100%;
    will-change: transform;
    transform: scale(1);
    transition: transform 2s cubic-bezier(0.77, 0, 0.175, 1), clip-path 0.1s linear;
    position: absolute;
}

.sepia {
    filter: grayscale(100%) brightness(80%);
}

.masked {
    clip-path: circle(25% at 50% 50%);
}

.member-name {
    position: relative;
    bottom: 0;
    color: white;
    z-index: 100000;

}

@media (max-width: 1200px) {
    .gallery {
        flex-direction: column;
        height: 1200px;
    }

}