@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,600;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,600;1,300&family=Oxygen:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap');

html {
    scroll-behavior: smooth;
}
html::-webkit-scrollbar {
    display: none;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Ms Madi'
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

section {
    height: 100vh;
    font-size: 40px;
    font-family: 'Oxygen', sans-serif;
    /* padding-top: 7rem; */
    width: 100%;

}

:root {
    --color-bg: #0F0F0F;
    --color-bg-light: #e8e4e4;
    --color-gradient: linear-gradient(90deg, #c6579b 0%, rgba(218,42,64,1) 99%);;
    --color-button: #000000;
    --color-nav: rgba(0,0,0, 0.4);
    --color-yellow: rgba(248,229,0,1);
    --color-purple: rgba(110,37,108,1);
    --color-blue: rgba(37,51,110,1);
    --color-red: rgba(239,55,53,1);
}


@media (max-width: 1200px) {
    .container {
        width: 100%;
    }
}