#footer{
    height: 40vh;
    /* background:   linear-gradient(180deg, rgba(64,67,79,1) 0%, rgba(19,17,17,1) 100%);; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
 
    
}

.info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    /* flex-wrap: wrap; */
    width: 100%;
    margin: 0;
    padding: 1rem 0 1rem 0;
}

.container {
    width: 1200px;
    height: 100%
}

.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 12pt;
}


.footer-childs {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
}

.logo {
    justify-self: center;
    align-self: center;
    align-items: center;
}

.footer-icons {
    margin: 5px;
    font-size: 16pt;
}

.contact-info span {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    
}

.contact-info p {
    margin-left: 10px;
}

.contact-info a {
    text-decoration: none;
    color: white;   
    transition: 0.4s;
}
.contact-info a:hover {

    color: #c35fa4;   
}

.copyrights {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.line-separator {
    width: 90%;
    height: 1px;
    background-color: rgba(255,255,255, 0.3);

}

.details {
    font-size: 10pt;
    margin-bottom: 0.7rem;
    margin-top: 0.7rem;
}

.section-title {
    margin: 0;
    font-size: 20pt;
}

.logo-spin {
    scale: 0.6;
    animation: spin 12s infinite linear;
}

.center{
    display: flex;
    justify-content: center!important;
    align-items: center !important;
    color: #fff;
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .telephons {
    display: flex;
    flex-direction: column;
  }

.section-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
}

.contact-info span{
    color: #fff;
}

@media (max-width: 1200px) {
    .container {
        width: 100%;
    }

    .info {
        flex-direction: column;
        align-items: center;
    }

    #footer {
        height: max-content;
    }

    .logo-spin{
        scale: 0.5;
    }
}