.thankyou-container{
    height: 70vh;
    /* background-color: #8a2be2; */
    background-image: url('../../assets/thankyou-bg.png');
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
}

.thankyou-container h1{
    font-size: 50px;
    color: white;
    text-align: center;
    line-height: normal;
    font-weight: bold;
}

.thankyou-container p{
    font-size: 25px;
    color: white;
    text-align: center;
}

.thankyou-buttons-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 0;
    
}


.button-28 a{
    color: rgb(255, 255, 255);
}


/* CSS */
.button-28 {
    appearance: none;
    /* background-color: transparent; */
    background-color: #1A1A1A;
    border: 2px solid #1A1A1A;
    border-radius: 15px;
    box-sizing: border-box;
    color: #3B3B3B;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    min-height: 60px;
    min-width: 0;
    outline: none;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 200px !important;
    height: 70px;
    margin: 0 1rem;
    will-change: transform;
}

.button-28:disabled {
    pointer-events: none;
}

.button-28:hover {
    color: #fff;
    background-color: transparent;
    /* background-color: #1A1A1A; */
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

.button-28:active {
    box-shadow: none;
    transform: translateY(0);
}