@import url(//fonts.googleapis.com/css?family=Lato:300:400);


.header {
    position: relative;
    text-align: center;
    height:fit-content ;
}
.bottom{
    position: relative;
    background-color: #e8e4e4;
}




.flex {
    /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
}

.waves {
    position: relative;
    width: 100%;
    /* height: 30vh; */
    margin-bottom: 0px;
    height: 100%;
    /*Fix for safari gap*/
    /* min-height: 150px; */
    /* max-height: 1000px; */
}

.content {
    position: relative;
    /* height: 20vh; */
    text-align: center;
    background-color: white;
}

/* Animation */

.parallax>use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

/* Shrinking for mobile */
/* @media (max-width: 768px) {
    .waves {
        height: 40px;
        min-height: 40px;
    }

    .content {
        height: 30vh;
    }

    h1 {
        font-size: 24px;
    }
} */