@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,600;1,300&display=swap');

.nav-modern {
    position: fixed;
    width: max-content;
    height: max-content;
    background-color: var(--color-nav);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-gap:4rem;
    left:50%;
    transform: translateX(-50%);
    top: 1rem;
    border-radius: 2rem;
    padding: 0.3rem 1.3rem;
    backdrop-filter: blur(7px);
    z-index: 3;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
    transition: all 1s;
    transition: 1s
}

nav-modern a{
    width: 100%;
}
nav a {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 23px;
    text-decoration: none;
    padding: 5px;
}

.nav-test{
    position: fixed; 
    width:100%;
    height: max-content;
    background-color: var(--color-nav);
    display: flex;
    justify-content: space-evenly;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    padding: 0.3rem 1.3rem;
    backdrop-filter: blur(7px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3);
    transition: all 1s;
    transition: 1s
}

.icon {
    display: none;
    align-self: flex-end;
}

.button-language{
    border: none;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 23px;
    text-decoration: none;
    padding: 5px;
}
#language-main-button{
    font-size: 21px!important;
}
@media screen and (max-width: 1200px) {
    .nav-modern a:not(:last-child) {
        display: none;
    }
    #language-main-button{
        display: none;
    }
    #language-dropdown{
        display: none;
    }
    /* .nav-test {
            justify-content: end;
        } */
        /* test commit */
    #selector {
        display: flex;
    }

    .nav-test a:not(:last-child) {
        display: none;
    }
    
    #selector {
        display: flex;
    }

    .nav-modern{
        width: fit-content!important;
    }
}


#dropdown{
    display: none;
}

ul{
    list-style-type: none;
    padding: 2rem;
    margin: 0;
}

li{
    margin-top: 2rem ;
}

.selector{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
}

.selector-button {
    background-color: transparent;
    border: none;
}

.selector-button:active{
    opacity: 0.5;
}






.dropbtn {
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    padding: 5px;
    border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
    font-size: 23px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    
    /* min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1; */
}

/* Links inside the dropdown */
.dropdown-content button {
    color: black;
    /* padding: 12px 16px; */
    /* text-decoration: none; */
    display: block;
    border: none;
    font-size: 23px;
    width: 100%;
    text-align: start;
}

/* Change color of dropdown links on hover */
.dropdown-content button:hover {
    background-color: #cacaca
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: #c35fa4;
}

.none{
    display: none;
}
