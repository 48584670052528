.App {
  text-align: center;
}

.App-header {
  background-color: var(--color-bg);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url("./assets/Circuit\ Board.png");
  background-attachment: fixed;
}
