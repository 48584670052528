@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,600;1,300&family=Oxygen:wght@300;400&display=swap");

.typewriter {
    color: rgb(255, 255, 255);
    width: fit-content;
    font-family: monospace;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0.15em solid #c4363e; /* The typwriter cursor */
    font-size: 100% !important;
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0.15em; /* Adjust as needed */
    animation: typing 1.5s steps(30, end), blink-caret 0.5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: #c4363e;
    }
}

button {
    background-color: transparent;
    color: rgb(255, 255, 255);
    padding: 13px 18px;
    margin: 10px 25px 0 25px;
    cursor: pointer;
}

#header a {
    text-decoration: none;
    /* color: rgb(255, 255, 255); */
    font-size: 15px;
    padding: 20px;
    font-family: "Oxygen", sans-serif;
}

#header {
    overflow: hidden;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    /* padding-top: 7rem; */
}

.border-gradient {
    border: 2.5px solid;
    border-image-slice: 1;
    /* border-width:2px; */
    clip-path: polygon(0 0, 12px 0, 12px 3px, 24px 3px, 24px 0, 100% 0, 100% 100%, 0 100%);
}
.border-gradient-card {
    border-image-source: var(--color-gradient);
}

.neomorphism {
    /* background-image: var(--color-gradient); */
    background-color: var(--color-bg-light);
    border: 2.5px transparent;
    border-radius: 4rem;
    width: 10.5rem;
    height: 4rem;
    background: linear-gradient(145deg, #cacaca, #f0f0f0);
    box-shadow: 12px 12px 24px #bebebe, -12px -12px 24px #ffffff;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;
}

.neomorphism:hover {
    box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff;
    border: 2.5px #bebebe;
}

.neomorphism a {
    color: black;
}

.filled a {
    color: rgb(255, 255, 255);
}

.filled {
    background-image: var(--color-gradient);
}

.logo {
    width: 80%;
    /* transform: translateX(-5%); */
    margin: 0;
    padding: 0;
}

.project-demo {
    height: 13rem !important;
    width: 20rem !important;
    background-image: var(--color-gradient);
    /* justify-items: center; */
    border-radius: 1rem;
    margin: 2rem auto 5rem auto;
    padding-top: 1rem;
    position: relative;
    z-index: 1;
    font-size: 19px;
    width: 100%;
    text-align: center;
    overflow: hidden;
    line-height: 22rem;
    box-shadow: 10px 11px 43px -15px rgba(0, 0, 0, 0.75);
    /* justify-content: center; */
    /* transform: translateX(40%); */
}

.project-demo img {
    height: 100%;
    right: 6rem;
    z-index: 3;
    transition: transform 0.3s;
    position: absolute;
    left: -1rem;
    top: -0.04rem;
}

.open {
    transform: translateY(-25%);
}

.close {
    transform: translateY(0%);
}
.row {
    width: 100%;
    height: 100%;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.info-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    /* margin-left: 7rem; */
    width: 100%;
    /* padding: 5rem; */
}

.info-header p {
    margin-bottom: 2rem;
}

.logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* padding: 5rem 2rem 2rem 0; */
}

.bottom-container {
    width: 100%;
    height: 100%;
    flex: 1 1 1;
    /* position: relative; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* transform: translateY(-4rem);  */
    /* flex:  1; */
    /* bottom: 0; */
    /* position: relative; */
    /* bottom: -5rem;   */
}
.buttons-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height:50%;
    background-color: var(--color-bg-light);
    /* z-index: 1000; */
}

/* .child {
    position: absolute;
} */

@media (max-width: 1200px) {
    .row {
        flex-direction: column;
    }
    .info-header {
        justify-content: center;
    }

    .container {
        width: 100%;
    }

    .typewriter{
    font-size: 30px !important;
    }

}

.project-demo #desc {
    z-index: 2;
    font-size: 19px;
    width: 100%;
    text-align: center;
    align-content: flex-end;
    margin-top: 10rem;
    position: absolute;
    bottom: 10px;
    transform: translateY(-10%);
}

.waves-container {
    width: 100vw;
    height: 50%;
    flex: 1;
}

.flex-header {
    display: flex;
    /* margin-top: 7rem; */

    /* flex: 50% 50%; */
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.container {
    width: 1200px;
    height: 100%;
    overflow: visible;
}








/* @keyframes slide-up {
  from {
      transform: translateY(0%);
  }
  to {
    transform: translateY(-25%);
  }
}

.project-demo img:not(:hover){
  animation: slide-down .3s steps(40, end) forwards;
}

@keyframes slide-down {
  from { translateY(cu)}
  to {
    transform: translateY(0%);
  }
} */
      
      /* .waves {
          margin-top: -1rem;
          height: 100%;
          background-color: "rgb(232, 228, 228)" !important;
          color: #e8e4e4 !important;
      } */